import { template as template_098564ccf8bb49f5825260cd40c1c34e } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_098564ccf8bb49f5825260cd40c1c34e(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
