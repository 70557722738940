import { template as template_b078b37cfd9c46c1b0518d71091f4c34 } from "@ember/template-compiler";
import { LinkTo } from "@ember/routing";
import { or } from "truth-helpers";
import dIcon from "discourse-common/helpers/d-icon";
import { i18n } from "discourse-i18n";
export default template_b078b37cfd9c46c1b0518d71091f4c34(`
  <LinkTo class="btn btn-flat back-button" @route={{@route}}>
    {{dIcon "chevron-left"}}
    {{i18n (or @label "back_button")}}
  </LinkTo>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
